import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../layout';
import PressCard from '../components/PressCard';
import './press.scss';

const PressPage = props => {
  const {
    data: {
      logos: { group: logos },
      additionals: { group: additionals },
      titles: { press: { titles } },
    },
    location,
  } = props;

  // console.log(props.data);

  return (
    <Layout pathname={location.pathname} title="Press" className="press-page simple-page">
      <div className="about-page-top-banner-container">
        <h1 className="about-page-top-banner-title">MLReef Press-kit</h1>
        <p className="about-page-top-banner-text">
          Official MLReef press kit including photos, logos, and our press release boilerplate.
        </p>
      </div>
      <div className="press-page-content simple-page-content">
        <section>
          <h2 className="">MLReef logos</h2>
          <p>PNG downloads have a transparent background, JPGs will have a white background.</p>

          <div className="press-page-content-container">
            {logos.map(resource => (
              <PressCard
                key={resource.fieldValue}
                resource={resource}
                titles={titles.logos}
              />
            ))}
          </div>
        </section>

        <section>
          <h2 className="">Additional images</h2>

          <div className="press-page-content-container">
            {additionals.map(resource => (
              <PressCard
                key={resource.fieldValue}
                resource={resource}
                titles={titles.additionals}
              />
            ))}
          </div>
        </section>

        <section className="mt-4 pt-3">
          <h2>Press release statement</h2>
          <div className="box border-rounded">
            <p>
              MLReef is a Machine Learning Operations (MLOps) platform that helps organizations to collaborate efficiently on ML projects.
            </p>
            <p>
            Machine Learning (ML) has seen an incredible uprise and impact in recent years. Mostly driven by increased data and computing availability its 
            use shifted from research to applied ML. Organizations around the globe are seeing ML as a competitive 
            advantage able to drive significant Return on Investment (ROI). This perspective is driving (and forcing) adoption 
            of ML, but not without its still very present operational and investment risks:
            </p>
            <ul>
              <li>
                Lack of talents able to build and sustain ML life cycles.
              </li>
              <li>
                High investment and maintenance costs of deployed tool chains.
              </li>
              <li>
                Difficult and inefficient access to ML content (data, use cases, best practices, scripts, etc). 
              </li>
              <li>
                Versatility and reproducibility in creating ML projects.
              </li>
              <li>
                Difficult collaboration and communication flow in the team and across organizations.
              </li>
            </ul>
            <p>
            ML Operations (short MLOps) aims at operationalizing the entire ML life cycle with the objective to solve many 
            of the above problems - similar how DevOps drove customer value by solving complexity and collaboration in software development. 
            At MLReef, we are developing a platform that focuses on collaboration via a holistic, easy to use und fully reproducible
            (and open) ML development environment. This way, our customers can drive value based on: 
            </p>
            <ul>
              <li>
                Fast and efficient prototyping, leveraged by community generated ML content.
              </li>
              <li>
                Collaborative environment for beginners and experts alike (easy usability vs. deep adaptability).
              </li>
              <li>
                Full reproducibility on data, scripts, parameters, and environments used.
              </li>
              <li>
                Centralized knowledge base for sustainable use of ML work within the organization.
              </li>
              <li>
                Full data security with on-premise and complete offline setup.
              </li>
            </ul>
            <p>
            <b>About the company</b>
            </p>
            <p>
            MLReef was founded in 2019 and has ever since focused on developing the MLOps platform MLReef.
            </p>
            <p>
            We strongly believe in the need to democratize the power of AI. This becomes especially true, when enabling the many 
            to adopt future technologies to improve their work and to create the radical new. AI is a new “energy source” which needs 
            to be affordable and accessible to all. 
            </p>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query FetchPressResources {
    logos: allFile(filter: {sourceInstanceName: {eq: "resources"}, relativeDirectory: {eq: "logos"}}) {
      group(field: name) {
        nodes {
          name
          publicURL
          extension
          relativePath
          internal {
            mediaType
          }
        }
        fieldValue
      }
      totalCount
    }

    additionals: allFile(filter: {sourceInstanceName: {eq: "resources"}, relativeDirectory: {eq: "additionals"}}) {
      group(field: name) {
        nodes {
          name
          publicURL
          extension
          relativePath
          internal {
            mediaType
          }
        }
        fieldValue
      }
      totalCount
    }

    fonts: allFile(filter: {sourceInstanceName: {eq: "resources"}, relativeDirectory: {eq: "fonts"}}) {
      group(field: name) {
        nodes {
          name
          publicURL
          extension
          relativePath
          internal {
            mediaType
          }
        }
        fieldValue
      }
      totalCount
    }

    titles: dataYaml {
      press {
        titles {
          additionals {
            resource
            text
          }
          logos {
            resource
            text
          }
        }
      }
    }
  }
`;

export default PressPage;
