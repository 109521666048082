import React, { useMemo } from 'react';
import './PressCard.scss';

const getFirst = ({ nodes }) => nodes && nodes
  .find(i => ['png', 'jpg'].includes(i.extension));

const getMediaType = node => node && node.internal && node.internal.mediaType;

const PressCard = props => {
  const {
    resource,
    titles,
  } = props;

  const title = useMemo(
    () => {
      const tit = titles.find((t) => t.resource === resource.fieldValue);
      return tit ? tit.text : undefined;
    },
    [titles, resource],
  );

  return (
    <div className="press-card card">
      {title && (<h4 className="press-card-title">{title}</h4>)}
      <div className="press-card-container card-container flex-column">
        <div title={resource.fieldValue} className="press-card-image flex-1 d-flex">
          {/^image/.test(getMediaType(getFirst(resource))) && (
            <div className="image-background m-auto">
              <img
                src={getFirst(resource).publicURL}
                alt={resource.fieldValue}
                className="press-image"
              />
            </div>
          )}
        </div>
        <div className="press-card-actions">
          {resource.nodes.map(file => (
            <a
              key={file.relativePath}
              className="file-link"
              href={file.publicURL}
              download
            >
              {file.extension.toUpperCase()}
            </a>
          ))}
        </div>

      </div>
    </div>
  );
};

export default PressCard;
